import { SentryError } from '@ifixit/sentry';
import {
   Flex,
   Link,
   Breadcrumb,
   BreadcrumbItem,
   Menu,
   MenuButton,
   MenuList,
   MenuItem,
   MenuItemProps,
   IconButton,
   Text,
   BreadcrumbItemProps,
   LinkProps,
   useBreakpointValue,
   FlexProps,
   BreadcrumbProps,
} from '@chakra-ui/react';
import { FaIcon } from '@ifixit/icons';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { memo, useState, useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import { useHiddenWrap } from './FlexHiddenWrap';

export interface BreadcrumbData {
   name: string;
   title?: string;
   url?: string;
   onClick?: () => void;
}

export type BreadCrumbsProps = {
   breadCrumbs: BreadcrumbData[];
   breadcrumbsToShow?: number;
   breadcrumbIcon?: JSX.Element;
   includeSchema?: boolean;
} & FlexProps;

export type StaticBreadCrumbsProps = {
   visibleBreadCrumbs: BreadcrumbData[];
   collapsedBreadCrumbs?: BreadcrumbData[];
   breadcrumbIcon?: JSX.Element;
   includeSchema?: boolean;
} & FlexProps &
   Pick<BreadcrumbProps, 'listProps'>;

type IFixitBreadcrumbItemProps = BreadcrumbData & {
   index: number;
   isLast: boolean;
   includeSchema: boolean;
} & BreadcrumbItemProps;

export const BreadCrumbs = memo(function DynamicBreadCrumbs({
   breadCrumbs,
   breadcrumbsToShow,
   breadcrumbIcon = <DefaultBreadcrumbIcon />,
   includeSchema = true,
   ...flexProps
}: BreadCrumbsProps) {
   if (breadcrumbsToShow != undefined && breadcrumbsToShow <= 0) {
      throw new SentryError('breadcrumbsToShow can only be undefined or must be greater than 0');
   }

   // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
   const isStatic = breadcrumbsToShow > 0;

   const visibleBreadCrumbs = isStatic
      ? // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        breadCrumbs.slice(breadCrumbs.length - breadcrumbsToShow)
      : breadCrumbs;

   const hiddenBreadCrumbs = isStatic
      ? // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        breadCrumbs.slice(0, breadCrumbs.length - breadcrumbsToShow)
      : [];

   const [wrappedBreadcrumbs, setWrappedBreadcrumbs] =
      useState<BreadcrumbData[]>(hiddenBreadCrumbs);

   const wrappedChildrenEffect = useCallback(
      (children: HTMLElement[]) => {
         if (isStatic) {
            return;
         }
         const wrappedNames = new Set(children.map(c => c.dataset.name));
         const wrapped = breadCrumbs.filter(b => wrappedNames.has(b.name));
         setWrappedBreadcrumbs(() => wrapped);
      },
      [breadCrumbs, isStatic]
   );

   const hiddenWrapProps = useHiddenWrap({ wrappedChildrenEffect });

   return (
      <StaticBreadCrumbs
         visibleBreadCrumbs={visibleBreadCrumbs}
         collapsedBreadCrumbs={wrappedBreadcrumbs}
         breadcrumbIcon={breadcrumbIcon}
         includeSchema={includeSchema}
         listProps={hiddenWrapProps}
         {...flexProps}
      />
   );
});

export function StaticBreadCrumbs({
   visibleBreadCrumbs,
   collapsedBreadCrumbs = [],
   breadcrumbIcon = <DefaultBreadcrumbIcon />,
   includeSchema = true,
   listProps,
   ...flexProps
}: StaticBreadCrumbsProps) {
   return (
      <Flex display="flex" flexShrink={1} flexGrow={1} alignItems="center" gap="6px" {...flexProps}>
         <IFixitCollapsedBreadcrumb
            breadCrumbs={collapsedBreadCrumbs}
            breadcrumbIcon={breadcrumbIcon}
         />
         <IFixitBreadcrumb
            includeSchema={includeSchema}
            breadCrumbs={visibleBreadCrumbs}
            breadcrumbIcon={breadcrumbIcon}
            listProps={listProps}
         />
      </Flex>
   );
}

function DefaultBreadcrumbIcon() {
   return (
      <Flex>
         <FaIcon icon={faChevronRight} h="2.5" display="flex" color="gray.400" mt="1px" />
      </Flex>
   );
}
const IFixitBreadcrumb = memo(function IFixitBreadcrumb({
   breadCrumbs,
   breadcrumbIcon,
   listProps,
   includeSchema,
}: Pick<BreadCrumbsProps, 'breadCrumbs' | 'breadcrumbIcon' | 'includeSchema'> &
   Pick<BreadcrumbProps, 'listProps'>) {
   const reversedBreadCrumbs = breadCrumbs.slice().reverse();

   const BreadCrumbItems = reversedBreadCrumbs.map((props, index) => {
      const isLastBreadCrumb = index === 0;
      return (
         <IFixitBreadcrumbItem
            key={props.name}
            // @ts-expect-error TS(2783) FIXME: 'name' is specified more than once, so this usage ... Remove this comment to see the full error message
            name={props.title}
            spacing="6px"
            {...props}
            index={index}
            isLast={isLastBreadCrumb}
            // @ts-expect-error TS(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
            includeSchema={includeSchema}
         />
      );
   });

   const breadcrumbSchema = includeSchema
      ? {
           itemScope: true,
           itemProp: 'breadcrumb',
           itemType: 'http://schema.org/BreadcrumbList',
        }
      : {};

   const breadcrumbHeight = '20px';

   return (
      <Breadcrumb
         separator={breadcrumbIcon}
         listProps={{
            padding: '0px',
            ...listProps,
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end',
            height: breadcrumbHeight,
            lineHeight: breadcrumbHeight,
         }}
         {...breadcrumbSchema}
      >
         {BreadCrumbItems}
      </Breadcrumb>
   );
}, isEqual);

const IFixitBreadcrumbItem = memo(function IFixitBreadcrumbItem({
   url,
   name,
   onClick,
   isLast,
   index,
   includeSchema,
   ...breadcrumbItemProps
}: IFixitBreadcrumbItemProps) {
   const breadcrumbItemSchema = includeSchema
      ? {
           itemScope: true,
           itemProp: 'itemListElement',
           itemType: 'http://schema.org/ListItem',
        }
      : {};

   const MetaTags = includeSchema ? (
      <>
         <meta itemProp="item" content={url} />
         <meta itemProp="name" content={name} />
         <meta itemProp="position" content={`${index + 1}`} />
      </>
   ) : null;

   const color = isLast ? 'gray.900' : 'gray.500';

   return (
      <BreadcrumbItem
         {...breadcrumbItemProps}
         {...breadcrumbItemSchema}
         isLastChild={isLast}
         data-name={name}
      >
         <Text
            as={Link}
            noOfLines={1}
            href={url}
            fontWeight={isLast ? 500 : 400}
            color={color}
            _visited={{ color: color }}
            _hover={{ textDecoration: 'none' }}
            onClick={onClick}
         >
            {name}
         </Text>
         {MetaTags}
      </BreadcrumbItem>
   );
}, isEqual);

const IFixitCollapsedBreadcrumb = memo(function IFixitCollapsedBreadcrumb({
   breadCrumbs,
   breadcrumbIcon,
}: Pick<BreadCrumbsProps, 'breadCrumbs' | 'breadcrumbIcon'>) {
   const iconSize = useBreakpointValue({ base: '14px', sm: '16px' }, { ssr: false });

   if (!breadCrumbs.length) {
      return null;
   }

   const CollapsedBreadCrumbItems = breadCrumbs.map(({ name, url, onClick }) => (
      <IFixitCollapsedBreadcrumbItem url={url} name={name} onClick={onClick} key={name} />
   ));

   return (
      <>
         <Menu>
            <MenuButton
               as={IconButton}
               aria-label="Options"
               colorScheme="gray"
               background="gray.300"
               variant="solid"
               size="xs"
               marginRight="1"
               minHeight={{ base: '20px', sm: '24px' }}
               minWidth={{ base: '26px', sm: '32px' }}
               icon={<FaIcon icon={faEllipsis} fontSize={iconSize} color="gray.500" />}
            />
            <MenuList zIndex={3}>{CollapsedBreadCrumbItems.reverse()}</MenuList>
         </Menu>
         {breadcrumbIcon}
      </>
   );
}, isEqual);

function IFixitCollapsedBreadcrumbItem({
   url,
   name,
   onClick,
   ...menuItemProps
}: BreadcrumbData & MenuItemProps & LinkProps) {
   return (
      <MenuItem
         as={Link}
         color="gray.900"
         _visited={{ color: menuItemProps.color || 'gray.900' }}
         _hover={{ textDecoration: 'none' }}
         fontSize="14px"
         href={url}
         onClick={onClick}
         {...menuItemProps}
      >
         {name}
      </MenuItem>
   );
}
