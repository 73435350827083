import { SystemProps } from '@chakra-ui/react';
import { useRef, useLayoutEffect, RefObject } from 'react';

export type HiddenWrapEffect = (_children: HTMLElement[], _container: HTMLElement) => void;
export function useHiddenWrap<T extends HTMLElement>({
   wrappedChildrenEffect,
   shownChildrenEffect,
}: {
   wrappedChildrenEffect?: HiddenWrapEffect;
   shownChildrenEffect?: HiddenWrapEffect;
}): {
   ref: RefObject<T>;
   display: SystemProps['display'];
   flexWrap: SystemProps['flexWrap'];
   overflow: SystemProps['overflow'];
} {
   const ref = useRef<T>();

   useLayoutEffect(() => {
      if (!ref?.current) {
         return;
      }

      const measure = () => {
         // @ts-expect-error TS(2345) FIXME: Argument of type 'T | undefined' is not assignable... Remove this comment to see the full error message
         onBrowserRender(ref.current, wrappedChildrenEffect, shownChildrenEffect);
      };

      measure();
      window.addEventListener('resize', measure);
      return () => {
         window.removeEventListener('resize', measure);
      };
   }, [ref.current, wrappedChildrenEffect, shownChildrenEffect]);
   // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<T | undefined>' is not assi... Remove this comment to see the full error message
   return { ref, display: 'flex', flexWrap: 'wrap', overflow: 'hidden' };
}

function onBrowserRender<T extends HTMLElement>(
   container: T,
   wrappedEffect: HiddenWrapEffect,
   shownEffect: HiddenWrapEffect
) {
   const buffer = container.offsetHeight / 2;
   const wrappedPX = container.offsetTop + buffer;
   const children = Array.from(container.children) as HTMLElement[];

   const wrappedChildren = children.filter(child => child.offsetTop > wrappedPX);
   const shownChildren = children.filter(child => child.offsetTop <= wrappedPX);
   wrappedEffect?.(wrappedChildren, container);
   shownEffect?.(shownChildren, container);
}
